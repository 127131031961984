<script>
import api from '@/libs/api';
import counterCheckmarkSvg from '#a/images/counter-checkmark.svg';
import AlertModal from '#c/uikit/AlertModal/AlertModal.vue';
import Button from '#c/uikit/Button.vue';

export default {
  name: 'ActivateView',

  data() {
    return {
      counterCheckmarkSvg,

      modal: {
        visible: false,
        type: null,
        title: null,
        description: null,
        buttonText: null,
        closeHandler: () => {},
      },
    };
  },

  computed: {
    organizations() {
      return this.$store.state.organizations;
    },

    selectedOrganization() {
      return this.organizations.find((org) => (
        org.code === this.$route.query.org
      ));
    },
  },

  watch: {
    // route-guard
    $route: {
      deep: true,
      immediate: true,
      handler() {
        const { code, org, acc } = this.$route.query;
        const hasParams = code && org && acc;
        if (!hasParams) {
          this.$router.replace({
            name: 'home',
          });
        }
      },
    },
  },

  methods: {
    async handleActivate() {
      const { code: hashCode, org, acc } = this.$route.query;
      const hasParams = hashCode && org && acc;

      if (!hasParams) {
        return;
      }

      try {
        const response = await api.proxy({
          method: 'post',
          endpoint: `/qrInfo/update/${hashCode}`,
        }, {
          account: acc,
          organization_code: org,
          connected: true,
        });

        const {
          connected,
          organization_code,
          account,
        } = response.data.qrInfo;

        if (connected) {
          this.showAlert({
            type: 'positive',
            title: 'Поздравляем!',
            description: 'Счетчик успешно активирован',
            buttonText: 'Продолжить',
            closeHandler: () => {
              this.$router.replace({
                name: 'review',
                query: {
                  org: organization_code,
                  acc: account,
                },
              });
            },
          });
        }
      } catch (error) {
        if (error.message) {
          this.showAlert({
            type: 'negative',
            title: 'Ошибка!',
            description: error.message,
            buttonText: 'Закрыть',
            closeHandler: this.hideAlert,
          });
        }
      }
    },

    showAlert(options) {
      this.modal.visible = true;
      this.modal.type = options.type;
      this.modal.title = options.title;
      this.modal.description = options.description;
      this.modal.buttonText = options.buttonText;
      this.modal.closeHandler = options.closeHandler;
    },

    hideAlert() {
      this.modal.visible = false;
      this.modal.type = false;
      this.modal.title = false;
      this.modal.description = false;
      this.modal.buttonText = false;
      this.modal.closeHandler = () => {};
    },
  },

  components: {
    Button,
    AlertModal,
  },
};
</script>

<template>
  <div>
    <div class="activate-view">
      <div class="activate-view__main">
        <h1>Активировать счетчик</h1>

        <dl
          v-if="selectedOrganization"
          class="activate-view__dl"
        >
          <div class="activate-view__dl-item">
            <dt class="activate-view__dt">Поставщик:</dt>
            <dd class="activate-view__dd">{{ selectedOrganization.label }}</dd>
          </div>
          <div class="activate-view__dl-item">
            <dt class="activate-view__dt">Лицевой счет:</dt>
            <dd class="activate-view__dd">{{ this.$route.query.acc }}</dd>
          </div>
        </dl>
      </div>

      <div class="activate-view__image" aria-hidden>
        <img :src="counterCheckmarkSvg" title="" />
      </div>

      <footer class="activate-view__footer">
        <Button @click.native="handleActivate">
          Активировать счетчик
        </Button>
      </footer>
    </div>

    <AlertModal
      v-if="modal.visible"
      :type="modal.type"
      :title="modal.title"
      :description="modal.description"
      :button-text="modal.buttonText"
      @close="modal.closeHandler"
    />
  </div>
</template>

<style lang="stylus" scoped>
  .activate-view {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    @media (min-width: 767px) {
      grid-template-columns: 1fr auto;
      grid-template-rows: 1fr auto;
      gap: 20px;

      .activate-view__main {
        grid-column: 1;
        grid-row-start: 1;
        grid-row-end: 2;
      }

      .activate-view__footer {
        grid-column: 1;
        grid-row-start: 2;
        grid-row-end: 3;
      }

      .activate-view__image {
        grid-column: 2;
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
  }

  .activate-view__main {}

  .activate-view__dl {
    display: flex;
    flex-direction: column;

    & > * ~ * {
      margin-top: 5px;
    }
  }

  .activate-view__dl-item {
    display: flex;

    & > .activate-view__dt ~ .activate-view__dd {
      margin-left: 10px;
    }
  }

  .activate-view__dt {}

  .activate-view__dd {
    margin: 0;
  }

  .activate-view__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;

    img {
      width: 100%;
      max-width: 250px;
      height: auto;
    }
  }

  .activate-view__footer {
    height: min-content;

    .btn {
      width: 100%;
    }

    @media (min-width: 767px) {
      .btn {
        width: auto;
      }
    }
  }
</style>
